import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import {
    format,
    startOfDay,
    isEqual,
    isBefore,
    isAfter,
    set,
    subHours,
    subMinutes,
    parse,
    isSameDay,
    differenceInDays,
} from 'date-fns';
import { Field, FieldProps } from 'formik';

import { colors } from '../../../styles/colors';
import { ErrorText } from './styled';
import { Period } from '../../schedule/models';
import { AnyObject, Appointment } from '../models';
import { Breakpoints } from '../../../dictionaries';
import { config } from '../../../config';
import { subscribeOnAppointmentsUpdate, reserveSlot } from '../../schedule/api';
import { pluralize, getPeriodsFromSchedule, timeDifferenceFilter } from '../../../utils';
import { getMinAvailableDate, getMaxAvailableDate } from './Schedule';

import Datepicker from './Datepicker';
import { has, includes } from 'lodash';
import { useStepsDispatch, useStepsState } from '../../schedule/provider';
import { useAdminState } from '../../shared/providers/admin/provider';
import { useManageState } from '../../shared/providers/manage/provider';

const periods = getPeriodsFromSchedule(config.schedule);


const Container = styled.div`
  position: relative;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 23px;

  @media (min-width: ${Breakpoints.sm}px) {
    flex-direction: row;
    align-items: flex-end;
  }
`;

const SlotsContainer = styled.div`
  background: ${colors.blue8};
  padding: 30px;
  position: relative;

  @media (min-width: ${Breakpoints.lg}px) {
    padding: 80px 60px;
  }
`;

const Slot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: -5px 0 30px;

  & > * {
    margin: 5px 0;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    flex-direction: row;
    margin: 0 -17px 20px;

    & > * {
      margin: 0 17px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const ChooseSlotBtn = styled.button`
  background: ${({ selected }: { selected: boolean }) =>
        selected ? colors.blue : colors.white};
  color: ${({ selected }: { selected: boolean }) =>
        selected ? colors.white : colors.blue};
  border: 1px solid ${colors.blue};
  text-transform: uppercase;
  font-size: 20px;
  transition: all 0.15s ease-in-out;
  padding: 10px 30px;
  border-radius: 10px;
  white-space: nowrap;
  cursor: ${({ selected }: { selected: boolean }) =>
        selected ? 'default' : 'pointer'};

  @media (min-width: ${Breakpoints.sm}px) {
    padding: 10px 50px;
  }

  @media (min-width: ${Breakpoints.md}px) {
    padding: 10px 80px;
    width: 350px;
  }

  &:disabled {
    color: ${({ selected }: { selected: boolean }) =>
        selected ? colors.white : colors.grey};
    border: 1px solid
      ${({ selected }: { selected: boolean }) =>
        selected ? colors.blue : colors.grey};
    cursor: default;
  }
`;

const Error = styled.div`
  position: absolute;
  bottom: -120px;
  left: 50%;
  transform: translateX(-50%);
`;

const DatePickerContainer = styled.div`
  display: block;
  line-height: 1.5;

  @media (min-width: ${Breakpoints.md}px) {
    display: ${({ alwaysShowDatePicker }: { alwaysShowDatePicker?: boolean }) =>
        alwaysShowDatePicker ? 'block' : 'none'};
  }
`;

const ChangeDateBtn = styled.div`
  display: inline-block;
  border: 0;
  background: none;
  color: ${colors.blue};
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 2px;
`;

const SelectedDate = styled.h3`
  color: rgba(38, 38, 38, 0.8);
  font-size: 22px;
  font-weight: 700;
  line-height: 1.5;
  margin-right: 10px;
`;

const shimmer = keyframes`
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
`;

const Shimmer = styled.div`
  height: 13px;
  width: 120px;
  border-radius: 2px;
  animation: ${shimmer} 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 400px 100%;
`;

interface Props {
    alwaysShowDatePicker?: boolean;
    appointment: Appointment;
    onFieldUpdate: (update: AnyObject) => void;
    onChangeDate?: (data: any) => void;
    isAdmin?: boolean;
    isManage?: any;
}

const Slots: React.FC<Props> = ({
    alwaysShowDatePicker,
    appointment,
    onFieldUpdate,
    onChangeDate,
    isAdmin,
    isManage
}) => {

    // const periods = getPeriodsFromSchedule(config.schedule);


    const [loading, setLoading] = useState(false);
    const [slots, setSlots] = useState<any>(periods);



    const {
        date,
        slot,
        minors,
        departureDateAndTime,
        isExpressSameDayTest,
        isRapidTest,
        location,
        reservationId,
        reservationCount
    } = appointment;
    const numberOfGuests = minors.length + 1;

    const { slotsList } = useStepsState();
    const { adminSlots } = useAdminState();
    const { updateFormValues } = useStepsDispatch();
    const { rescheduleslots } = useManageState();

    const locationId = location?.qbenchCustomerId;

    const [selectedDate, setSelectedDate] = useState(date ? parse(date, config.dateFormat, new Date()) : new Date());

    useEffect(() => {
        setLoading(true);

        let unsubscribe: any;

        //Validate the persons count and make the customer select the slots again
        async function updateReserveData() {
            setLoading(true);
            updateFormValues({
                reservationId:"",
                slot: undefined
            })
            setLoading(false);
        }

        if(reservationId !== undefined && reservationId !== "" && reservationCount !== numberOfGuests){
            updateReserveData();
        }

        async function getSlots() {
            try {
                if (date && locationId) {

                    const parsed = parse(date, config.dateFormat, new Date());
                    const dayAsNumber = new Date(parsed).getDay();
                
                    let updatedPeriods: any = []
                    if (!isAdmin && !isManage) {

                        slotsList.map((item: any) => {
                            let testType:any = isRapidTest ? "rapid" : (isExpressSameDayTest ? "express":  "standard")
                            if(item.testType ==  testType){
                                item[`${location?.qbenchCustomerId}`].slotsByDay[`${dayAsNumber}`].map((res: any)=>{
                                    updatedPeriods.push({
                                        ...res,
                                        startTime: parse(res.startTime, 'h:mmaaaaa', new Date())
                                    })        
                                })
                            }
                            
                        })

                    } else if(isManage) {

                        rescheduleslots.map((item: any) => {
                            let testType:any = isRapidTest ? "rapid" : (isExpressSameDayTest ? "express":  "standard")
                            if(item.testType ==  testType){
                                item[`${location?.qbenchCustomerId}`].slotsByDay[`${dayAsNumber}`].map((res: any)=>{
                                    updatedPeriods.push({
                                        ...res,
                                        startTime: parse(res.startTime, 'h:mmaaaaa', new Date())
                                    })        
                                })
                            }
                            
                        })

                    }else if(isAdmin) {

                        adminSlots.map((item: any) => {
                            let testType:any = isRapidTest ? "rapid" : (isExpressSameDayTest ? "express":  "standard")
                            if(item.testType ==  testType){
                                item[`${location?.qbenchCustomerId}`].slotsByDay[`${dayAsNumber}`].map((res: any)=>{
                                    updatedPeriods.push({
                                        ...res,
                                        startTime: parse(res.startTime, 'h:mmaaaaa', new Date())
                                    })        
                                })
                            }
                        })

                    } else{
                        updatedPeriods=[]
                    }

                    unsubscribe = subscribeOnAppointmentsUpdate(
                        { date, locationId, isRapidTest },
                        (result: any) =>
                            setSlots(
                                updatedPeriods.reduce((acc: any, period: any, i: any) => {
                                    const a = acc;

                                    let starttime: any = period.startTime

                                    const startTime = set(starttime, {
                                        year: parsed.getFullYear(),
                                        date: parsed.getDate(),
                                        month: parsed.getMonth(),
                                    });

                                    if (!departureDateAndTime) {
                                        return a;
                                    }

                                    const parsedDeparture = parse(
                                        departureDateAndTime,
                                        config.dateTimeFormat,
                                        new Date()
                                    );

                                    let slotIsOutOfAllowedPeriod: any = "";

                                    if(isRapidTest){

                                        slotIsOutOfAllowedPeriod =
                                            isBefore(
                                                startTime,
                                                subHours(parsedDeparture, 72)
                                            ) || 
                                            isAfter(
                                                startTime,
                                                subMinutes(parsedDeparture, 1)
                                            );

                                    } else {

                                        slotIsOutOfAllowedPeriod =
                                            isBefore(
                                                startTime,
                                                subHours(parsedDeparture, isExpressSameDayTest ? config.vipSlots.maxAdvanceHours : config.maxAdvanceHours)
                                            ) ||
                                            isAfter(
                                                startTime,
                                                subHours(parsedDeparture, isExpressSameDayTest ? config.vipSlots.minAdvanceHours : config.minAdvanceHours)
                                            );
                        
                                    }

                                    if (slotIsOutOfAllowedPeriod) {
                                        return a;
                                    }

                                    var dateNow = new Date()

                                    var startDate: any = location?.startDate

                                    // let validateStandard:any = isBefore(dateNow, startTime);
                                    // if(!isExpressSameDayTest && !isRapidTest){
                                    //     validateStandard= (isBefore(dateNow, startTime) && !isSameDay(new Date(), startTime));
                                    // }
                                    if (isBefore(dateNow, startTime)) {
                                        
                                        if (isAfter(parsedDeparture, new Date(startDate)) && (isAfter(parsed, new Date(startDate)) || isEqual(parsed, new Date(startDate)))) {

                                            a.push({
                                                ...period,
                                                startTime,
                                                index: period.index,
                                                available:
                                                    period.available -
                                                    ((result && result.data.appointmentsPerPeriod?.[period.index]) || 0),
                                            });
                                        }
                                    }
                                    return a;
                                }, [])
                            )
                    );

                    await new Promise<void>((resolve) => {
                        setTimeout(() => {
                            resolve();
                        }, 800);
                    });
                }

                setLoading(false);
            } catch (e) {
                console.error(e);

                setLoading(false);
            }
        }
        setSelectedDate(date ? parse(date, config.dateFormat, new Date()) : new Date())

        new Promise<void>((resolve) => {
            setTimeout(() => {
                getSlots();
            }, 1000);
        });

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [date, departureDateAndTime, isExpressSameDayTest, location, locationId, isRapidTest]);

    return (
        <Field name="slot">
            {({
                form: { setFieldValue, setFieldError, values },
                field: { value },
                meta: { error },
            }: FieldProps) => {
                const parsedDeparture = departureDateAndTime
                    ? parse(departureDateAndTime, config.dateTimeFormat, new Date())
                    : new Date();

                return (
                    <Container>
                        <Header>
                            <SelectedDate>
                                {format(selectedDate, config.weekDateFormat)}
                            </SelectedDate>
                            <DatePickerContainer alwaysShowDatePicker={alwaysShowDatePicker}>
                                <Datepicker
                                    value={selectedDate}
                                    maxDate={getMaxAvailableDate(
                                        location,
                                        isExpressSameDayTest,
                                        parsedDeparture,
                                        isRapidTest
                                    )}
                                    minDate={getMinAvailableDate(
                                        location,
                                        isExpressSameDayTest,
                                        parsedDeparture,
                                        isRapidTest
                                    )}
                                    onChange={(newDepartureDate) => {

                                        setSelectedDate(newDepartureDate)
                                        if (onChangeDate) {
                                            onChangeDate(newDepartureDate)
                                        }
                                    }}
                                    customInput={<ChangeDateBtn>Change date</ChangeDateBtn>}
                                />
                            </DatePickerContainer>
                        </Header>
                        <SlotsContainer>
                            {slots.length > 0 ? (
                                slots.map(({ label, startTime, available, index }: { label: any, startTime: any, available: any, index: any }, i: any) => {
                                    const preSelectedSlotDate = slot?.date
                                        ? parse(slot.date, config.dateFormat, new Date())
                                        : null;

                                    const isSelected =
                                        !!preSelectedSlotDate &&
                                        isEqual(selectedDate, preSelectedSlotDate) &&
                                        locationId === slot?.locationId &&
                                        index === value?.period;


                                    return (
                                        <Slot key={i}>
                                            <ChooseSlotBtn
                                                selected={isSelected}
                                                type="button"
                                                onClick={async() => {

                                                    if (isSelected || !location) {
                                                        return;
                                                    }
                                                    const desiredSlot = {
                                                        date: format(selectedDate, config.dateFormat),
                                                        period: index,
                                                        locationId: location?.qbenchCustomerId,
                                                        label: label
                                                    };
                                                    localStorage.setItem("desiredSlot", JSON.stringify(desiredSlot))

                                                    appointment.slot = desiredSlot;

                                                    onFieldUpdate({ slot: desiredSlot });
                                                    setFieldValue('slot', desiredSlot);

                                                    setLoading(true);
                                                    await reserveSlot(
                                                        desiredSlot,
                                                        slot,
                                                        numberOfGuests,
                                                        isRapidTest,
                                                        reservationId,
                                                        false,
                                                        appointment
                                                        ).then((res)=>{
                                                            if(res.data.status == 200){
                                                                updateFormValues({
                                                                    reservationId: res.data.reservationId,
                                                                    reservationCount: res.data.numberOfGuests
                                                                })
                                                            }
                                                            setLoading(false);
                                                    }).catch((e) => { 
                                                        // setFieldError('slot', e.message) 
                                                    });
                                                }}
                                                disabled={
                                                    available === 0 ||
                                                    available < numberOfGuests ||
                                                    loading
                                                }
                                            >
                                                {label}
                                            </ChooseSlotBtn>
                                            {loading ? (
                                                <Shimmer />
                                            ) : (
                                                <span>{pluralize(available, 'slot')} available</span>
                                            )}
                                        </Slot>
                                    );
                                })
                            ) : (
                                <>No available slots</>
                            )}
                        </SlotsContainer>
                        <Error>
                            <ErrorText hasError={error !== undefined}>{error}</ErrorText>
                        </Error>
                    </Container>
                );
            }}
        </Field>
    );
};

export default Slots;
