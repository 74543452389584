import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { useReactToPrint } from 'react-to-print';
import { PDFDownloadLink } from '@react-pdf/renderer';

import {
    Container,
    Content,
    PageHeader,
    PageTitle,
    Button,
} from '../../../shared/components/styled';
import { useStepsState } from '../../provider';
import { Breakpoints } from '../../../../dictionaries';
import { colors } from '../../../../styles/colors';
import PrintableConfirmation from './PrintableConfirmation';
import DownloadableConfirmation from './DownloadableConfirmation';
import { getPeriodsFromSchedule } from '../../../../utils';
import { config } from '../../../../config';
import ios from '../../../../assets/ios.png';
import android from '../../../../assets/android.png';
import cp from '../../../../assets/cp.png';
import mixpanel from 'mixpanel-browser';

const Wrap = styled.div`
  background: ${colors.blue8};
  margin-bottom: -15px 0 33px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  & > * {
    margin: 15px 0;
  }

  @media (min-width: ${Breakpoints.sm}px) {
    flex-direction: row;
    margin: -10px 0 30px;

    & > * {
      margin: 0 10px;
    }
  }

  @media (min-width: ${Breakpoints.md}px) {
    padding: 46px 34px;
  }
`;

export const ConfirmationNumber = styled.div`
  text-align: center;

  @media (min-width: ${Breakpoints.sm}px) {
    text-align: left;
  }
  
  @media print {
    text-align: left;    
  }

  strong {
    display: block;
    font-size: 28px;
    font-weight: bold;
    margin-top: 6px;

    @media (min-width: ${Breakpoints.md}px) {
      font-size: 30px;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  margin: -5px 0;

  & > * {
    margin: 5px 0;
  }
`;

const Text = styled.p``;

const BoldedText = styled.p`
  font-weight:bold;
  line-height: 2
`;
const UnorderedList = styled.ul`
  line-height: 2
  margin-left:15px;
`;
const ListItem = styled.li`
&:before {
  content: "-";
  position: absolute;
  margin-left: -1em;
}
`;
const Bold = styled.h1`
  font-weight:500;
  font-size: 24px;
  
`;
const Box = styled.div`
  width:37%;
  display: flex;
  justify-content: space-between;
  margin-bottom:10px;
  @media (max-width: ${Breakpoints.sm}px) {
    width: auto;
    a {
      padding-right: 10px
    }
  }
`;
const Line = styled.hr`
  width:50%;
  height:1px;
  background-color:blue;
  margin-left:0
`;

const CTAButton = styled.button`
  display: inline-block;
  width: 35%;
  color: ${colors.white};
  text-align: center;
  padding: 15px;
  background: ${colors.green};
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border: 0;
  font-size: 18px;
  line-height: 12px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 5%;
  margin: 7%;
  margin-left: 30%;
  @media (max-width: ${Breakpoints.sm}px) {
    width: auto;
    margin-left: auto;
    line-height: normal;
  }

`;

const Confirmation: React.FC = () => {
    const { form, slotsList } = useStepsState();
    const printableRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => printableRef.current,
    });
    // const periods = useMemo(() => getPeriodsFromSchedule(config.schedule), []);
    // console.log(form)
    const findHwl = form.IsAirline;

    useEffect(() => {
        mixpanel.track("Confirm booking your appointment");
    }, [])

    return (
        <Container size="xl">
            <Content>
                <PageHeader>
                    <PageTitle>
                        Thank you for your order!
                        <br />
                        We are verifying your appointment details. You will receive a confirmation email shortly.
                    </PageTitle>
                </PageHeader>
                {/* <Wrap> */}
                    {/* <PrintableConfirmation
                        ref={printableRef}
                        form={form}
                        // periods={periods}
                        periods={slotsList}
                    /> */}
                {/* </Wrap> */}
                <Text>
                    You should receive a confirmation email along with your confirmation number within 15 minutes of booking your appointment. If you do not receive a confirmation email, please make sure to check your spam/junk folder, then contact <a href={`mailto:support@worksitelabs.com`}> support@worksitelabs.com.</a>
                </Text>
                <BoldedText>
                    Additional Information:
                </BoldedText>
                <UnorderedList>
                    <ListItem>
                        Please bring your ID and confirmation email/number to your appointment
                    </ListItem>
                    <ListItem>
                        To make changes to your appointment and to view your test results when they are ready, visit <a href={"https://www.schedulecovidtesting.com/manage"}> https://www.schedulecovidtesting.com/manage </a>
                    </ListItem>
                    <ListItem>
                        If you have any questions or need assistance, contact support@worksitelabs.com or call +1 213-948-2461. Live agents are available from 5AM-12AM PT,  7 days a week.
                    </ListItem>
                </UnorderedList>

                <CTAButton
                    type="button"
                    onClick={() => window.location.href = "https://worksitelabs.com/scheduling/"}
                    style={{ backgroundColor: '#4DA1E1' }}
                >
                    Schedule Another Appointment
                </CTAButton>
                {findHwl ? (
                    <div>
                        <Line />
                        <Bold>
                            Download the CommonPass app to seamlessly access your results with your Worksite Labs login.
                        </Bold>
                        <div style={{
                            maxWidth: "50%",
                            display: "block",
                            height: "10%"
                        }}>
                            <img src={cp} alt="" width="300" height="150" />
                        </div>
                        <Box>
                            <a href="https://apps.apple.com/us/app/commonpass/id1548682047" target="_blank"><img src={ios} alt="" width="180" height="70" /></a>
                            <a href="https://play.google.com/store/apps/details?id=org.thecommonsproject.android.commonpass" target="_blank"><img src={android} alt="" width="180" height="70" /></a>
                        </Box>
                        <div style={{ marginTop: "4%" }}>
                            <a href="https://commonpass.org/" target="_blank">Click here for more information about CommonPass</a>
                        </div>
                    </div>
                ) : ""}


            </Content>
        </Container>
    );
};

export default Confirmation;
