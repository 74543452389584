import React from 'react';
import ReactSelect from 'react-select';
import styled from 'styled-components';
import { Field, FieldProps, FieldValidator } from 'formik';

import { ErrorText } from '../styled';
import { colors } from '../../../../styles/colors';

const Container = styled.div`
  position: relative;
`;

const Error = styled.div`
  position: absolute;
  top: 60px;
  left: 6px;
`;

const styles = {
  selectElement: {
    container: (provided: any) => ({
      ...provided,
      width: '100%',
      marginBottom: '35px',
    }),
    control: (_: any, { selectProps: { width } }: any) => ({
      width: width,
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: '#FFF',
      border: `1px solid ${
        state.selectProps.hasError ? colors.red : colors.grey
      }`,
      borderRadius: '10px',
      padding: '12px 24px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: '5px',
    }),
  },
};

interface Props<T> {
  name: string;
  disabled?: boolean;
  options: { label: string; value: T }[];
  styles?: {
    [key: string]: string | number;
  },
  validate?: FieldValidator;
  onChange?: (newValue: T) => void;
  isRequired?: boolean;
}

function Select<T>({
  name,
  disabled,
  options,
  styles: wrapperStyles,
  validate,
  onChange,
  isRequired
}: Props<T>) {
  return (
    <Field
      name={name}
      validate={validate}
    >
      {({
        field: { value },
        form: { setFieldValue },
        meta: { touched, error },
      }: FieldProps) => (
        <Container style={wrapperStyles}>
          <ReactSelect
            hasError={touched && !!error}
            value={value}
            isDisabled={disabled}
            isRequired={isRequired}
            onChange={(selectedValue) => {
              setFieldValue(name, selectedValue);
              //onChange && onChange(selectedValue?.value);

              onChange && onChange(selectedValue!.value);
            }}
            options={options}
            width="100%"
            styles={styles.selectElement}
          />
          <Error>
            <ErrorText hasError={touched && !!error}>
              {error}
            </ErrorText>
          </Error>
        </Container>
      )}
    </Field>
  );
};

export default Select;
