import { firebase } from '../../../../firebase';
import { AppointmentListItem, AppointmentsListParams, ConsolidationParams, Appointment } from './models';
import xlsx from 'node-xlsx';
import { config } from '../../../../config';
import { parse, format } from 'date-fns';
// import qbench from '../';

export const signIn = async (email: string, password: string) => {
  firebase.auth().signInWithEmailAndPassword(email, password);
  window.location.href = '/dashboard';
};

export const signOut = async () => firebase.auth().signOut();

export const getAppointmentsByDate = async (params: AppointmentsListParams) =>
  firebase
    .functions()
    .httpsCallable('getAppointmentsByDate', { timeout: 540 * 1000 })(params);

export const getAppointmentsByconfirmationId = async (params: AppointmentsListParams) =>
  firebase
    .functions()
    .httpsCallable('getAppointmentsByconfirmationId', { timeout: 540 * 1000 })(params);

export const getfilterwithrecords = async (params: ConsolidationParams) =>
  firebase
    .functions()
    .httpsCallable('getfilterwithrecords', { timeout: 540 * 1000 })(params);


export const getAllAppointmentSlottime = async () =>
  firebase.functions().httpsCallable('getAllAppointmentSlottime')();

export const sendConfirmationEmail = async (emails: string[]) =>
  firebase.functions().httpsCallable('sendConfirmationEmail')(emails);

export const downloadResultsPDF = async (sampleId: string) => {
  var windowReference: any = window.open();
  const { data } = await firebase
    .functions()
    .httpsCallable('getResultsPDFDownloadLink')(sampleId);

  if (data?.url) {
    // window.open(data?.url, '_blank');
    // let a: any = await document.createElement("a");
    // document.body.appendChild(a);
    // a.href = `${data?.url}`;
    // a.download = 'report';
    // a.click();
    // document.body.removeChild(a);
    windowReference.location = data?.url;
  }
};

export const updateAppointment = async ({
  firebaseId,
  qbenchId,
  sampleId,
  update,
  minorIndex,
}: {
  firebaseId: string;
  qbenchId: string;
  sampleId: string;
  minorIndex: number | null;
  update: Partial<AppointmentListItem>;
}) =>
  firebase.functions().httpsCallable('updateAppointment')({
    firebaseId,
    sampleId,
    qbenchId,
    update,
    minorIndex,
  });

export const getQbenchResults = async () =>
  firebase.functions().httpsCallable('getQbenchResults')();


export const getApptConsolidationDetails = async () =>
  firebase.functions().httpsCallable('getApptConsolidationDetails')();

export const getApptbasedonLocation = async () =>
  firebase.functions().httpsCallable('getApptbasedonLocation')();

export const getApptbasedonGender = async () =>
  firebase.functions().httpsCallable('getApptbasedonGender')();

export const getApptbasedonresults = async () =>
  firebase.functions().httpsCallable('getApptbasedonresults')();

// export const getCountyReport = async (runDate?: any) =>
// firebase.functions().httpsCallable('getCountyReport')({"runDate": runDate});

export const getCountyReport = (runDate?: any) =>
  firebase
    .firestore()
    .collection("qbenchconsolidationresults")
    .where('tests.complete_date_formated', '==', runDate)
    .get()
    // .then(parseSnapshot);
    .then((snapshot) => {

      const consolidationdetails: any = snapshot.docs.map(
        (doc: any) => doc.data()
      );


      const positiveconsolidationdetails = consolidationdetails.filter(function (
        item: any
      ) {
        return item.tests.results == 'DETECTED';
      });

      const negativeconsolidationdetails = consolidationdetails.filter(function (
        item: any
      ) {
        return item.tests.results == 'NOT DETECTED';
      });

      return {
        Allconsolidationdetails: consolidationdetails,
        Positiveconsolidationdetails: positiveconsolidationdetails,
        Negativeconsolidationdetails: negativeconsolidationdetails,
      }
    });


    export const generatecountyReport = async (appointments: any) => {
      const data = [
        ['FirstName',
          'LastName',
          'PatientIdentifier',
          'DOB',
          'Gender',
          'Race',
          'Ethnicity',
          'Patient_Street_Address',
          'Apartment_Number',
          'City',
          'State',
          'Zipcode',
          'PatientPhoneNumber',
          'Patient Email',
          'OrderingFacility',
          'Referring Physician',
          'Physician address',
          'Physician City',
          'Physician State',
          'Physician Zipcode',
          'Physician phone number',
          'Accession #',
          'Specimen Collection Date',
          'Specimen Received Date',
          'Specimen Type',
          'Test reported date',
          'Test Code_LOINC',
          'Test name',
          'Result',
          'PerformingFacility',
          'CLIA',
          'Pregnancy Status',
          'County Name', 'Sample ID'],
        ...appointments.reduce((acc: any[], appt: any) => {
          const a = acc;
    
          const row = [`${appt.samples !== undefined
            ? appt.samples['X_PATIENT_FIRST_NAME']
            : ''
            }`,
          `${appt.samples !== undefined
            ? appt.samples['X_PATIENT_LAST_NAME']
            : ''
          }`,
            '',
          `${appt.samples !== undefined
            ? appt.samples['X_PATIENT_DOB']
            : ''
          }`,
          appt.sex,
          appt.race,
          appt.ethnicity,
          `${appt.address !== undefined
            ? appt.address['address']
            : ''
          }`,
    
          `${appt.address !== undefined
            ? appt.address['address']
            : ''
          }`,
          `${appt.address !== undefined
            ? appt.address['city']
            : ''
          }`,
          `${appt.address !== undefined
            ? appt.address['state']
            : ''
          }`,
          `${appt.address !== undefined
            ? appt.address['zipCode']
            : ''
          }`,
          appt.phone,
          appt.email,
            'Work Site Lab',
          `${appt.location !== null && appt.location.medicalDirector !== undefined ? appt.location.medicalDirector : "NA"}`,
            'NA',
            'NA',
            'NA',
            'NA',
            'NA',
          `${appt.samples !== undefined
            ? appt.samples['X_PATIENT_EXTERNAL_ID']
            : ''
          }`,
          `${appt.date
          }`
            ,
          `${appt.tests !== undefined
            ? appt.tests['complete_date_formated']
            : ''
          }`
            ,
          `${appt.samples !== undefined && appt.samples !== null && appt.samples.accessioning_type !== undefined && appt.samples.accessioning_type !== null && appt.samples.accessioning_type['value'] !== null && appt.samples.accessioning_type['value'] !== undefined
            ? appt.samples.accessioning_type['value']
            : ''
          }`,
          `${appt.samples !== undefined && appt.samples['time_of_collection'] !== undefined && appt.samples['time_of_collection'] !== null && appt.samples['time_of_collection'] !== ''
            ? format(
              new Date(appt.samples['time_of_collection']),
              config.dateFormat
            )
    
            : ''
          }`,
          `${appt.tests !== undefined && appt.tests !== null && appt.tests.assay !== undefined && appt.tests.assay !== null && appt.tests.assay['title'] !== undefined && appt.tests.assay['title'] !== null
            ? appt.tests.assay['title'] == 'Atila iAMP NAAT RT-PCR COVID-19 Detection' ? "94500 - 6"
              : (appt.tests.assay['title'] == 'Quidel Lyra NAAT RT-PCR COVID-19 Detection' ? "94559 - 2"
                : appt.tests.assay['title'] == 'Biorad DD PCR' ? "94533 - 7" : '')
            : ''
          }`,
          `${appt.tests !== undefined && appt.tests !== null && appt.tests.assay !== undefined && appt.tests.assay !== null && appt.tests.assay['title'] !== undefined && appt.tests.assay['title'] !== null
            ? appt.tests.assay['title']
            : ''
          }`,
          `${appt.tests !== undefined && appt.tests !== null
            ? appt.tests['results']
            : ''
          }`
            ,
          `${appt.location !== undefined && appt.location !== null && appt.location.performingfacility !== undefined && appt.location.performingfacility !== null
            ? `Worksite Labs -${appt.location.performingfacility}`
            : ''
          }`,
          `${appt.location !== undefined && appt.location !== null && appt.location.CLIA !== undefined && appt.location.CLIA !== null
            ? appt.location.CLIA
            : ''
          }`,
            'NA',
          `${appt.address !== undefined && appt.address.county !== undefined && appt.address.county !== null
            ? appt.address['county']
            : ''
          }`,
          `${appt.sampleId
          }`
    
          ];
    
          a.push(row);
    
          return a;
        }, []),
      ];
      const buffer = xlsx.build([{ name: 'mySheetName', data: data }]);
      const blob = new Blob([buffer]);
      saveAs(blob, `report.xlsx`);
    }
    


export const updateAppointmentdata = async (params: Appointment) =>
  firebase
    .functions()
    .httpsCallable('updatedaywiseAppointment', { timeout: 540 * 1000 })(params);

export const getAppointmentdata = async (params: any) =>
  firebase
    .functions()
    .httpsCallable('getAppointmentdata', { timeout: 540 * 1000 })(params);


// export const updateAppointmentdata = async (appointment: Appointment) => {


//   firebase
//   .firestore().collection(config.firestoreCollections.appointments)
//   .doc(appointment.id)
//   .update({ 
//     location:appointment.location,firstName:appointment.firstName,
//     lastName:appointment.lastName,
//     email:appointment.email,
//     phone:appointment.phone,
//     birthDate:appointment.birthDate,
//     address:appointment.address,
//     slot:appointment.slot,
//     date:appointment.date,
//     minors:appointment.minors
//   });

//   //qbench.updateSample(qbenchId, sampleId, update);

// }


// // firebase
// // .functions()
// // .httpsCallable('getfilterwithrecords', { timeout: 540 * 1000 })(params);



