import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import {
    subHours,
    isBefore,
    startOfDay,
    parse,
    format,
    isAfter,
} from 'date-fns';
import { Formik, Form, FormikErrors, FormikValues } from 'formik';
import { Button } from './styled';
import { colors } from '../../../styles/colors';
import {
    Container,
    // PageHeader,
    PageTitle,
    PageSubTitle,
    Content,
} from './styled';
import pin from '../../../assets/pin.svg';
import { Breakpoints } from '../../../dictionaries';
import { config } from '../../../config';
import { AnyObject, Appointment, Location } from '../models';

import ChangeLocationModal from './ChangeLocationModal';
import Slots from './Slots';
import NavigationButtons from './NavigationButtons';
import Datepicker, { DatepickerField } from './Datepicker';
import FormLabel from './form/FormLabel';
import Checkbox from './form/Checkbox';
import { cloneDeep, flatMap, includes, isEqual } from 'lodash';
import { useStepsDispatch, useStepsState } from '../../schedule/provider';
import { useSharedDispatch, useSharedState } from '../provider';
import { reserveSlot, releaseSlot } from '../../schedule/api';
import { useManageDispatch, useManageState } from '../../shared/providers/manage/provider';
import LocationModal from '../../schedule/components/LocationModal';
import RapidAlertModal from './RapidAlertModal';
import { convertPricing } from '../../../utils';

const ChangeLocationButton = styled.button`
  display: inline-block;
  border: 0;
  background: none;
  color: ${colors.darkBlue};
  cursor: pointer;
`;

const PinIcon = styled.div`
  position: absolute;
  left: 0;
  top: 4px;
  display: inline-block;
  vertical-align: middle;
  margin: -2px 5px 0 0;
  width: 14px;
  height: 20px;
  background: url(${pin}) no-repeat;
  background-size: cover;
`;

const HeaderLocation = styled.div`
  position: relative;
  padding-left: 24px;
  text-align: left;

  @media (min-width: ${Breakpoints.sm}) {
    flex-direction: row;
  }
`;

const PageContent = styled.div`
  border-top: 1px solid ${colors.blue};
  padding-top: 25px;

  @media (min-width: ${Breakpoints.lg}px) {
    padding-top: 45px;
  }
`;

const AboveColumns = styled.div`
  max-width: 650px;
  margin-bottom: 20px;
`;

const Columns = styled.div`
  display: flex;
  margin: 0 -30px;

  & > * {
    margin: 0 30px;
  }
`;

const DatepickerWrapper = styled.div`
  display: none;

  @media (min-width: ${Breakpoints.md}px) {
    display: block;
    flex: 1 1 auto;
  }
`;

const SlotsWrapper = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    flex: 2 1 auto;
  }
`;

const LocationNameAndAddress = styled.div`
  margin-right: 10px;
`;

const PageHeader = styled.div`
  margin-bottom: 40px;
  flex: 1;
`;

const HeadarContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const TestTypeContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 117px;
    width: 550px;
    margin: 10px;
    justify-content: center;
    text-align: center;
    align-content: center;
`;

const InnerBox = styled.div`
    border: 1px solid rgb(26, 150, 219);
    margin: 0px 10px;
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    cursor: pointer;
    align-items: center;
    display: grid;
    background: ${({ selected }: { selected?: boolean }) => selected ? colors.blue : colors.white};
`;

const TestTypeTitle = styled.p`
    font-size: medium;
    font-weight: bold;
    color: ${({ selected }: { selected?: boolean }) => selected ? colors.white : colors.blue};

    @media (max-width: ${Breakpoints.sm}px) {
      font-size: 15px;
      font-weight: bold;
      color: ${({ selected }: { selected: boolean }) => selected ? colors.white : colors.blue};
    }
`;

const TestCostLabel = styled.p`
    font-size: 40px;
    font-weight: bold;
    color: ${({ selected }: { selected: boolean }) => selected ? colors.white : colors.blue};

    @media (max-width: ${Breakpoints.sm}px) {
      font-size: 30px;
      font-weight: bold;
      color: ${({ selected }: { selected: boolean }) => selected ? colors.white : colors.blue};
    }
`;

const TestDurationInfoLabel = styled.p`
    font-size: 12px;
    font-weight: bold;
    color: ${({ selected }: { selected: boolean }) => selected ? colors.white : colors.blue};

    @media (max-width: ${Breakpoints.sm}px) {
      font-size: 9px;
      font-weight: bold;
      color: ${({ selected }: { selected?: boolean }) => selected ? colors.white : colors.blue};
    }
`;

const RefundInfoText = styled.div`
    font-weight: bold;
`



interface Props {
    title?: string;
    onSchedule: (values: FormikValues) => void;
    onFieldUpdate: (update: AnyObject) => void;
    onLocationChange?: () => void;
    appointment: Appointment;
    confirmBtnText?: string;
    loading?: boolean;
    isManage?: boolean;
    isAdmin?: boolean;
    prices?: any;
    standardTest?:any;
}

export function getMinAvailableDate(
    location: Location | null,
    isExpressSameDayTest: boolean,
    departureDateAndTime: Date,
    isRapidTest: boolean,
): Date {

    let getTimeZoneOffSet = new Date(departureDateAndTime).getTimezoneOffset();
    if (isExpressSameDayTest && location && location.hasVipSlots) {

        if (
            isBefore(
                departureDateAndTime,
                parse(config.schedule.endTime, 'h:mmaaaaa', departureDateAndTime)
            )
        ) {

            return subHours(departureDateAndTime, config.vipSlots.maxAdvanceHours);  //departureDateAndTime;
        }


    }

    if (isRapidTest && location && location.rapidTest) {

        if (
            isBefore(
                departureDateAndTime,
                parse(config.schedule.endTime, 'h:mmaaaaa', departureDateAndTime)
            )
        ) {

            return subHours(departureDateAndTime, config.vipSlots.maxAdvanceHours);  //departureDateAndTime;
        }


    }

    if (
        isBefore(
            subHours(startOfDay(departureDateAndTime), getTimeZoneOffSet == 420 || getTimeZoneOffSet == 300 || getTimeZoneOffSet == 240 ? 71 : config.maxAdvanceHours),
            new Date()
        )
    ) {
        return new Date();
    }

    // avoid to open up previous day without available slots in datepicker
    if (
        isAfter(
            departureDateAndTime,
            parse(config.schedule.endTime, 'h:mmaaaaa', departureDateAndTime)
        )
    ) {
        return subHours(departureDateAndTime, config.minAdvanceHours);
    }

    return subHours(departureDateAndTime, getTimeZoneOffSet == 420 || getTimeZoneOffSet == 300 || getTimeZoneOffSet == 240 ? 71 : config.maxAdvanceHours);
}

export function getMaxAvailableDate(
    location: Location | null,
    isExpressSameDayTest: boolean,
    departureDateAndTime: Date,
    isRapidTest: boolean,
): Date {

    let getTimeZoneOffSet = new Date(departureDateAndTime).getTimezoneOffset();
    if (isExpressSameDayTest && location && location.hasVipSlots) {
        if (
            isBefore(
                departureDateAndTime,
                parse(config.schedule.startTime, 'h:mmaaaaa', departureDateAndTime)
            )
        ) {


        }

        const overTime = new Date(departureDateAndTime).getHours();
        return subHours(departureDateAndTime, overTime >= 12 ? 24 : config.vipSlots.minAdvanceHours);
    }

    if (isRapidTest && location && location.rapidTest) {
        const overTime = new Date(departureDateAndTime).getHours();
        return subHours(departureDateAndTime, 3);
    }

    // avoid to open up next day without available slots in datepicker
    if (
        isBefore(
            departureDateAndTime,
            parse(config.schedule.startTime, 'h:mmaaaaa', departureDateAndTime)
        )
    ) {
        return subHours(departureDateAndTime, getTimeZoneOffSet == 420 || getTimeZoneOffSet == 300 || getTimeZoneOffSet == 240 ? 71 : config.maxAdvanceHours);
    }

    return subHours(departureDateAndTime, config.minAdvanceHours);
}

const Schedule: React.FC<Props> = ({
    title,
    appointment,
    onSchedule,
    onFieldUpdate,
    onLocationChange,
    confirmBtnText,
    loading,
    isManage,
    isAdmin,
    prices,
    standardTest
}) => {
    const {
        location,
        slot,
        departureDateAndTime,
        isExpressSameDayTest,
        date,
        minors,
        isRapidTest,
        travelType,
        destination
    } = appointment;


    // const { prices } = useStepsState();
    const { apptPrices } = useManageState();
    const { updateStandradTest, updateAppointment } = useManageDispatch();

    // const { standradTest } = useSharedState();
    const { upateTestType } = useSharedDispatch();
    const { updateFormValues } = useStepsDispatch();

    // const [standard, setStandrad] = useState(standradTest);
    // const [express, setExpress] = useState(isExpressSameDayTest);
    const [showChangeDatetimeModal, setShowChangeDatetimeModal] = useState(false);

    useEffect(() => {
        updateStandradTest(isRapidTest ? false: (isExpressSameDayTest ? false : true));
        // updateStandradTestAdmin(isRapidTest ? false: (isExpressSameDayTest ? false : true));
    }, [])

    const [showRapidAlertModal, setShowRapidAlertModal] = useState(false);
	const handleRapidModalClose = () => {
		setShowRapidAlertModal(false);
	  };

    const locationStartDate = location
        ? parse(location.startDate, config.dateFormat, new Date())
        : null;

    const pageTitle = ( standardTest || isExpressSameDayTest || isRapidTest) ? "Pick a date and time" : "Select your test type";
    const { goToPrevStep } = useStepsDispatch();

    const rollbackisExpressSameDayTest = useMemo(() => cloneDeep(isExpressSameDayTest), [isManage]);
    const rollbackisRapidTest = useMemo(() => cloneDeep(isRapidTest), [isManage]);
    // const AmoutPaid = rollbackisExpressSameDayTest ? (apptPrices.expedited * (minors.length + 1)) : (apptPrices.standard * (minors.length + 1))
    // const refundableAmount = rollbackisExpressSameDayTest ? standard ? (AmoutPaid - (60 * (minors.length + 1))): 0 : 0;
    // const AdditonalCharge = !rollbackisExpressSameDayTest ? isExpressSameDayTest ? (60 * (minors.length + 1)) : 0 : 0;

    return (
        <Formik
            enableReinitialize
            initialValues={{
                slot,
                departureDateAndTime: departureDateAndTime
                    ? parse(departureDateAndTime, config.dateTimeFormat, new Date())
                    : null,
                isExpressSameDayTest,
                isRapidTest
            }}
            onSubmit={onSchedule}
            validate={(values) => {
                const errors: FormikErrors<FormikValues> = {};
                if (!values.slot) {
                    errors.slot = 'Please select slot';
                }

                return errors;
            }}
        >
            {({ values }) => (
                <Container size="xl">
                    <Content>
                        <HeadarContent>
                            <PageHeader>
                                <PageTitle>{pageTitle}</PageTitle>
                                <PageSubTitle>
                                    <HeaderLocation>
                                        <PinIcon />
                                        <LocationNameAndAddress>
                                            <span style={{ fontWeight: "bold" }}>{location?.name}</span>,
                                            <p>
                                                {location?.address1} {location?.address2}
                                            </p>
                                        </LocationNameAndAddress>
                                        {onLocationChange && (
                                            <ChangeLocationButton type="button" onClick={onLocationChange}>
                                                Change location
                                            </ChangeLocationButton>
                                        )}
                                    </HeaderLocation>
                                </PageSubTitle>
                            </PageHeader>
                            <TestTypeContainer>
                            <InnerBox
                                    selected={isRapidTest}
                                    style={{
                                        border: !location?.rapidTest ? "1px solid #D3D3D3" : "1px solid rgb(26, 150, 219)",
                                        background: !location?.rapidTest ? "#D3D3D3" : isRapidTest ? colors.blue : colors.white,
                                        cursor: !location?.rapidTest ? "not-allowed" : "pointer"
                                    }}
                                    onClick={ async () => {

                                        if (!location?.rapidTest) {
                                            return
                                        }
                                        upateTestType(false);
                                        updateStandradTest(false);
                                        setShowRapidAlertModal(true);
                                        onFieldUpdate({
                                            slot: undefined,
                                            isExpressSameDayTest: false,
                                            isRapidTest: true,
                                        });
                                        if (!values.departureDateAndTime) {
                                            return;
                                        }
                                        const overTime = new Date(values.departureDateAndTime).getHours();
                                        const newDate = subHours(values.departureDateAndTime, overTime >= 12 ? 24 : config.rapidSlots.minAdvanceHours)  //values.departureDateAndTime 
                                        onFieldUpdate({
                                            slot: undefined,
                                            isRapidTest: true,
                                            isExpressSameDayTest: false,
                                            date: format(newDate, config.dateFormat),
                                            reservationId: null
                                        });
                                    }} >
                                    <TestTypeTitle selected={isRapidTest} style={{ color: !location?.rapidTest ? "#939393" : isRapidTest ? colors.white : colors.blue }} >
                                        RAPID TEST
                                    </TestTypeTitle>
                                    <TestCostLabel selected={isRapidTest} style={{ color: !location?.rapidTest ? "#939393" : isRapidTest ? colors.white : colors.blue }}>
                                    ${convertPricing(false, true, prices, travelType, destination, location?.qbenchCustomerId)}
                                    </TestCostLabel>
                                    <TestDurationInfoLabel selected={isRapidTest} style={{ color: !location?.rapidTest ? "#939393" : isRapidTest ? colors.white : colors.blue }}>
                                        Results within 1.5 hours
                                    </TestDurationInfoLabel>
                                </InnerBox>
                                <InnerBox
                                    selected={isExpressSameDayTest}
                                    style={{
                                        border: !location?.hasVipSlots ? "1px solid #D3D3D3" : "1px solid rgb(26, 150, 219)",
                                        background: !location?.hasVipSlots ? "#D3D3D3" : isExpressSameDayTest ? colors.blue : colors.white,
                                        cursor: !location?.hasVipSlots ? "not-allowed" : "pointer"
                                    }}
                                    onClick={() => {

                                        if (!location?.hasVipSlots) {
                                            return
                                        }

                                        onFieldUpdate({
                                            slot: undefined,
                                            isExpressSameDayTest: true,
                                            isRapidTest: false,
                                        });

                                        upateTestType(false);
                                        updateStandradTest(false);

                                        if (!values.departureDateAndTime) {
                                            return;
                                        }

                                        const overTime = new Date(values.departureDateAndTime).getHours();
                                        const newDate = subHours(values.departureDateAndTime, overTime >= 12 ? 24 : config.vipSlots.minAdvanceHours)  //values.departureDateAndTime 

                                        onFieldUpdate({
                                            slot: undefined,
                                            isExpressSameDayTest: true,
                                            isRapidTest: false,
                                            date: format(newDate, config.dateFormat),
                                            reservationId: null
                                        });

                                    }} >
                                    <TestTypeTitle selected={isExpressSameDayTest} style={{ color: !location?.hasVipSlots ? "#939393" : isExpressSameDayTest ? colors.white : colors.blue }} >
                                        EXPRESS TEST
                                    </TestTypeTitle>
                                    <TestCostLabel selected={isExpressSameDayTest} style={{ color: !location?.hasVipSlots ? "#939393" : isExpressSameDayTest ? colors.white : colors.blue }}>
                                        ${convertPricing(true, false, prices, travelType, destination, location?.qbenchCustomerId)}
                                    </TestCostLabel>
                                    <TestDurationInfoLabel selected={isExpressSameDayTest} style={{ color: !location?.hasVipSlots ? "#939393" : isExpressSameDayTest ? colors.white : colors.blue }}>
                                        Results within 12 hours
                                    </TestDurationInfoLabel>
                                </InnerBox>
                                <InnerBox
                                    aria-disabled={true}
                                    selected={standardTest}
                                    style={{
                                        // border: isManage && rollbackisExpressSameDayTest ? "1px solid #D3D3D3" : "1px solid rgb(26, 150, 219)",
                                        // background: isManage && rollbackisExpressSameDayTest ? "#D3D3D3" : standard ? colors.blue : colors.white,
                                        // cursor: isManage && rollbackisExpressSameDayTest ? "none" : "pointer"
                                    }}
                                    onClick={() => {

                                        upateTestType(true);
                                        updateStandradTest(true);
                                        onFieldUpdate({
                                            slot: undefined,
                                            isExpressSameDayTest: false,
                                            isRapidTest: false
                                        });

                                        if (!values.departureDateAndTime) {
                                            return;
                                        }

                                        const newDate = subHours(
                                            startOfDay(values.departureDateAndTime),
                                            config.maxAdvanceHours
                                        ); 

                                        upateTestType(true);
                                        updateStandradTest(true);

                                        onFieldUpdate({
                                            slot: undefined,
                                            isExpressSameDayTest: false,
                                            isRapidTest: false,
                                            date: format(newDate, config.dateFormat),
                                            reservationId: null
                                        });

                                    }}>
                                    <TestTypeTitle selected={standardTest}>
                                        STANDARD TEST
                                    </TestTypeTitle>
                                    <TestCostLabel selected={standardTest}>
                                        ${convertPricing(false, false, prices, travelType, destination, location?.qbenchCustomerId)}
                                    </TestCostLabel>
                                    <TestDurationInfoLabel selected={standardTest}>
                                        {includes(config.locationsfor36Hrs, location?.qbenchCustomerId) ? "Results within 36 hours" : "Results within 24 hours" }
                                    </TestDurationInfoLabel>
                                </InnerBox>
                            </TestTypeContainer>
                        </ HeadarContent>
                        <PageContent>
                            {
                            !standardTest && !isExpressSameDayTest && !isRapidTest &&
                                <div style={{ textAlign: 'center' }}>
                                    <Button
                                        onClick={() => {
                                            goToPrevStep();
                                        }}
                                        style={{
                                            width: '24%',
                                        }}
                                        libraryType="default"
                                        type="button"

                                    >
                                        Return
                                    </Button>
                                </div>
                            }

                            <>
                                <Form>
                                    {
                                        (standardTest || isExpressSameDayTest || isRapidTest) &&
                                        <AboveColumns>
                                            <FormLabel label="Flight departure date & time (use the final leg of departure)">
                                                <DatepickerField
                                                    name="departureDateAndTime"
                                                    showTimeSelect
                                                    minDate={
                                                        locationStartDate === null ||
                                                            isBefore(locationStartDate, new Date())
                                                            ? new Date()
                                                            : locationStartDate
                                                    }
                                                    onChange={(newDepartureDate) => {

                                                        //This time zone offset is for pacific daylight time
                                                        //Will need to add the other time zones offset later.
                                                        let getTimeZoneOffSet = new Date(newDepartureDate).getTimezoneOffset();

                                                        const overTime = new Date(newDepartureDate).getHours();

                                                        let newDate = values.isExpressSameDayTest
                                                            ? subHours(newDepartureDate, overTime >= 12 ? 24 : config.vipSlots.minAdvanceHours)  // startOfDay(newDepartureDate)
                                                            // : subHours(newDepartureDate, config.maxAdvanceHours);
                                                            : subHours(newDepartureDate, getTimeZoneOffSet == 420 || getTimeZoneOffSet == 300 || getTimeZoneOffSet == 240 ? 71 : config.maxAdvanceHours)

                                                        if (isBefore(newDate, new Date())) {
                                                            newDate = startOfDay(new Date());
                                                        }

                                                        onFieldUpdate({
                                                            slot: undefined,
                                                            departureDateAndTime: format(
                                                                newDepartureDate,
                                                                config.dateTimeFormat
                                                            ),
                                                            date: format(newDate, config.dateFormat),
                                                            reservationId: null
                                                        });
                                                    }}
                                                />
                                            </FormLabel>
                                        </AboveColumns>
                                    }
                                    {values.departureDateAndTime && (
                                        <Columns>
                                            <DatepickerWrapper>
                                                <Datepicker
                                                    value={
                                                        date
                                                            ? parse(date, config.dateFormat, new Date())
                                                            : new Date()
                                                    }
                                                    onChange={(newDate) =>
                                                        onFieldUpdate({
                                                            slot: undefined,
                                                            reservationId: null,
                                                            date: format(newDate, config.dateFormat),
                                                        })
                                                    }
                                                    minDate={getMinAvailableDate(
                                                        location,
                                                        values.isExpressSameDayTest,
                                                        values.departureDateAndTime,
                                                        values.isRapidTest,
                                                    )}
                                                    maxDate={getMaxAvailableDate(
                                                        location,
                                                        values.isExpressSameDayTest,
                                                        values.departureDateAndTime,
                                                        values.isRapidTest,
                                                    )}
                                                    inline
                                                />
                                            </DatepickerWrapper>
                                            <SlotsWrapper>
                                                <Slots
                                                    isManage={isManage}
                                                    isAdmin={isAdmin}
                                                    appointment={appointment}
                                                    onFieldUpdate={onFieldUpdate}
                                                />
                                                {
                                                    isManage && rollbackisExpressSameDayTest == false && isExpressSameDayTest == true &&
                                                    <div style={{ margin: "20px 10px 0px 0px" }} >
                                                        <div style={{fontWeight:"bold"}} >
                                                            *Note
                                                        </div>
                                                        {
                                                            isExpressSameDayTest &&
                                                            <RefundInfoText>
                                                               If upgrading from a Standard Test to an Express Test or Rapid test, a full refund for your paid Standard Tests will be credited to the original credit card in 7-15 business days. There will be a new charge for the Express Tests.
                                                            </RefundInfoText>
                                                        }

                                                    </div>
                                                }
                                                {
                                                    (isManage|| isAdmin) && rollbackisRapidTest == false && isRapidTest == true &&
                                                    <div style={{ margin: "20px 10px 0px 0px" }} >
                                                        <div style={{fontWeight:"bold"}} >
                                                            *Note
                                                        </div>
                                                        {
                                                            isRapidTest &&
                                                            <RefundInfoText>
                                                               If upgrading from a Standard Test or Express Test to a Rapid test, a full refund for your paid Standard Tests or Express Tests will be credited to the original credit card in 7-15 business days. There will be a new charge for the Rapid Tests.
                                                            </RefundInfoText>
                                                        }

                                                    </div>
                                                }
                                                <NavigationButtons
                                                    loading={loading}
                                                    confirmBtnText={confirmBtnText}
                                                />
                                            </SlotsWrapper>
                                        </Columns>
                                    )}
                                </Form>

                            </>
                        </PageContent>
                    </Content>
                    <ChangeLocationModal openModal={() => setShowChangeDatetimeModal(false)} />
                    <LocationModal />
                    <RapidAlertModal 
                        handleModalClose={handleRapidModalClose} 
						open={showRapidAlertModal}
                    />
                </Container>
            )}
        </Formik>
    );
};

export default Schedule;
