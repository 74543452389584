import React, { useState } from 'react';
import styled from 'styled-components';
import { Breakpoints } from '../../../dictionaries';
import { Button } from './styled';
import { colors } from '../../../styles/colors';
import question from "../../../assets/question.png"
import Modal from './Modal';

const Content = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    width: 540px;
  }
`;
//margin: 0 -5px;
const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  float: right;
  margin: 10px 0px 10px 0px;

  & > * {
    margin: 0 5px;
  }
`;

//font-size: 28px;
const Title = styled.h2`
  color: ${colors.darkBlue};
  font-weight: 700;
  font-size: 17px;
  margin-bottom: 25px;
`;

export const Icon = styled.div`
  position: absolute;
  left: 0;
  top: 1px;
`;


export const QuestionIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  background: url(${question}) no-repeat center center;
`;

type Props = {
	open: boolean;
	handleModalClose: () => void,
}
const RapidAlertModal: React.FC<Props> = ({ handleModalClose, open }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	return (
		<Modal open={open} onClose={handleModalClose} maxWidth={600}>
			<Content>
				<div style={{ display: 'flex' }} >
					<img src={question} style={{ margin: "0px 10px 0px 0px", width: "25px", height: "25px" }} />
					<div>
						<Title>{"Rapid Testing"}</Title>
						<div style={{ margin: "10px 0px 10px 0px" }} >
							<div>{"You have selected the 1.5 Hour Rapid Test for your appointment. There is a limit of 4 Rapid Tests per hour.  Please select an available time slot(s) to accommodate you and your family as needed."}</div>
							{/* <div>{"Please select an available time slot(s) to accommodate you and your family as needed."}</div> */}
						</div>
						<ButtonsContainer>
							<Button
								libraryType="primary"
								size="sm"
								type="button"
								onClick={() => {
									handleModalClose();
								}}
							>
								{'OK'}
							</Button>
						</ButtonsContainer>
					</div>
				</div>
			</Content>
		</Modal>
	);
};

export default RapidAlertModal;
