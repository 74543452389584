import React from 'react';
import { Switch, Route } from 'react-router-dom';

import routes from './routes';

import Steps from './modules/schedule/components/Schedule';

function App() {
  return (
    <Switch>
      <Route path={routes.schedule} exact>
        <Steps />
      </Route>
      <Route
        path={routes.manage}
        component={() => {
          window.location.href = 'https://manage.schedulecovidtesting.com';
          return null;
        }}
      />
      <Route
        path={routes.admin}
        component={() => {
          window.location.href = 'https://admin.schedulecovidtesting.com';
          return null;
        }}
      />
      <Route
        path={routes.commonpass.default}
        component={() => {
          window.location.href = 'https://pass.schedulecovidtesting.com';
          return null;
        }}
      />
    </Switch>
  );
}

export default App;
