import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';

import {
    Container,
    Content,
    PageHeader,
    PageTitle,
} from '../../shared/components/styled';
import { useStepsState, useStepsDispatch } from '../provider';

import NavigationButtons from '../../shared/components/NavigationButtons';
import LocationButtonGroup from '../../shared/components/form/LocationButtonGroup';
import mixpanel from 'mixpanel-browser';
import { useSharedDispatch } from '../../shared/provider';

const Location: React.FC = () => {
    const {
        form: { location, email },
    } = useStepsState();
    const { goToNextStep, updateFormValues } = useStepsDispatch();
    const { upateTestType } = useSharedDispatch();

    //Update the email to lower case
    useEffect(() => {
        let lowerCaseEmail: any = email.toLowerCase()
        updateFormValues({ email: lowerCaseEmail });
    }, [])

    return (
        <Container>
            <Content>
                <PageHeader>
                    <PageTitle>Select a location</PageTitle>
                </PageHeader>
                <Formik
                    initialValues={{
                        location,
                    }}
                    onSubmit={(values) => {
                        updateFormValues(values);
                        mixpanel.track("Select a location");
                        goToNextStep();
                    }}
                >
                    {() => (
                        <Form>
                            <LocationButtonGroup />
                            <NavigationButtons />
                        </Form>
                    )}
                </Formik>
            </Content>
        </Container>
    );
};

export default Location;
