import React, { useCallback, useState, createContext, useMemo, useEffect } from 'react';

import { Appointment } from '../../models';
import { Result } from '../admin/models';
import { ManageState, ManageDispatch, appointmentList } from './models';
import { ManagePage } from './dictionaries';
import { getPrices } from '../../../schedule/api';
import { config } from '../../../../config';
import { getSiteHostName } from '../../../../utils';
import { getSlotsConfigurations } from "../../api";

export const ManageContext = createContext<ManageState>({
  currentPage: ManagePage.VerifyIdentity,
  appointment: null,
  results: null,
  appointmentList: null,
  rescheduleslots:[],
  apptPrices: {
    standard: 0,
    expedited: 0,
    rapid:0
  },
  showLocation: false,
  showStandradTest: false,
  IsManageAirline:true
});

export const ManageDispatchContext = createContext<ManageDispatch>({
  goToPage() {},
  updateAppointment() {},
  updateResults() {},
  updateResultsScreen(){},
  updateRescheduleSlots(){},
  toggleLocation(){},
  updateStandradTest(){}
});

export const ManageProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentPage, setCurrentPage] = useState(ManagePage.VerifyIdentity);
  const [appointment, setAppointment] = useState<Appointment | null>(null);
  const [results, setResults] = useState<Result[] | null>(null);

  const [appointmentList, setAppointmentList] = useState<any>(null);
  const [rescheduleslots, setRescheduleslots] = useState([]);
  const [showLocation, setLocationPopup] = useState(false);
  const [showStandradTest, setShowStandradTest] = useState(false);
  const [IsManageAirline,setIsManageAirline]=useState(true);

  const [apptPrices, setPrices] = useState({
    standard: 0,
    expedited: 0,
    rapid:0
  });

  useEffect(() => {
    getPrices().then((result) => {
      const newPrices = {
        ...apptPrices,
      };

      result.data.data.forEach((price: any) => {
        if (price.product === config.products.standard) {
          newPrices.standard = price.unit_amount / 100;
        }

        if (price.product === config.products.expedited) {
          newPrices.expedited = price.unit_amount / 100;
        }

        if (price.product === config.products.rapid) {
          newPrices.rapid = price.unit_amount / 100;
        }

      });
      setPrices(newPrices);
    });

    getSlotsConfigurations().then((result)=>{
      console.log("manage", result);
      setRescheduleslots(result.data);
    })


    const IsManageAirline: boolean = getSiteHostName();
    setIsManageAirline(IsManageAirline)
  }, [])

  const goToPage = useCallback(
    (newPage: ManagePage) => {
      setCurrentPage(newPage);
    },
    [setCurrentPage]
  );

  const updateStandradTest = useCallback(
    (show: any) => {
      setShowStandradTest(show);
    },
    [setShowStandradTest]
  );

  const toggleLocation = useCallback(
    (show: boolean) => {
      setLocationPopup(show);
    },
    [setLocationPopup]
  );

  const updateAppointment = useCallback(
    (update: any) => {
      setAppointment({
        ...appointment,
        ...update
      });
    },
    [setAppointment]
  );

  const updateRescheduleSlots = useCallback(
    (appointment: any) => {
      setRescheduleslots(appointment);
    },
    [setRescheduleslots]
  );


  const updateResults = useCallback(
    (results: Result[]) => setResults(results),
    [setResults]
  );

  const updateResultsScreen = useCallback(
    (appointmentList: any) => setAppointmentList(appointmentList),
    [setResults]
  );


  const store = useMemo(
    () => ({
      currentPage,
      appointment,
      results,
      appointmentList,
      rescheduleslots,
      apptPrices,
      showLocation,
      showStandradTest,IsManageAirline
    }),
    [currentPage, appointment, results, appointmentList, apptPrices, showLocation, showStandradTest,IsManageAirline]
  );

  const dispatch = useMemo(
    () => ({
      goToPage,
      updateAppointment,
      updateResults,
      updateResultsScreen,
      updateRescheduleSlots,
      toggleLocation,
      updateStandradTest
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <ManageContext.Provider value={store}>
      <ManageDispatchContext.Provider value={dispatch}>
        {children}
      </ManageDispatchContext.Provider>
    </ManageContext.Provider>
  );
};

export const useManageState = () => {
  const context = React.useContext(ManageContext);

  if (typeof context === 'undefined') {
    throw new Error(
      '`useManageState` hook must be used within a `Provider` component'
    );
  }

  return context;
};

export const useManageDispatch = () => {
  const context = React.useContext(ManageDispatchContext);

  if (typeof context === 'undefined') {
    throw new Error(
      '`useManageDispatch` hook must be used within a `Provider` component'
    );
  }

  return context;
};
