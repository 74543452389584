import { format, isBefore, parse } from 'date-fns';
import xlsx from 'node-xlsx';
import { saveAs } from 'file-saver';
import { firebase } from '../../firebase';
import { config } from '../../config';
import { FirebaseLocation } from './models';
import { getPeriodsFromSchedule, Last7Days } from '../../utils';
import { Location } from './models';
import { filter, orderBy } from 'lodash';
import axios from 'axios';
const periods = getPeriodsFromSchedule(config.schedule);
const Reportperiods = getPeriodsFromSchedule(config.Reportschedule);

export const getOpenedLocations = () =>
  firebase
    .firestore()
    .collection(config.firestoreCollections.locations)
    .where('isOpened', '==', true)
    .get()
    .then((snapshot) => {
      let locationArray: any = [];
      snapshot.docs
        .map((doc) => doc.data() as FirebaseLocation)
        .map((loc: any) => {
          if (isBefore(loc.visibleSince.toDate(), new Date())) {
            locationArray.push({
              ...loc,
              startDate: format(loc.startDate.toDate(), config.dateFormat),
            });
          }
        });
      locationArray = orderBy(locationArray, ['name'], ['asc']);
      return locationArray;
    });

function generateReport(appointments: any, locationName?: string) {
  const data = [
    ['Date', 'First Name', 'Last Name', 'Phone', 'Flight Date And Time'],
    ...appointments.reduce((acc: any[], appt: any) => {
      const a = acc;
      const row = [
        `${appt.date} ${
          appt.slot?.period !== undefined
            ? Reportperiods[appt.slot?.period].label
            : ''
        }`,
        appt.firstName,
        appt.lastName,
        appt.phone,
        appt.departureDateAndTime,
      ];
      a.push(row);
      return a;
    }, []),
  ];
  const buffer = xlsx.build([{ name: 'mySheetName', data: data }]);
  const blob = new Blob([buffer]);
  saveAs(blob, `report${locationName ? `-${locationName}` : ''}.xlsx`);
}

export const generateAppointmentsReport = async (location: Location) => {
  const pastDates: Array<any> = Last7Days();

  const today = new Date();
  const previousday = new Date(today);
  previousday.setDate(previousday.getDate() - 7);
  const previousdate = format(previousday, 'LL/dd/yyyy');

  firebase
    .firestore()
    .collection(config.firestoreCollections.qbenchacknowledgement)
    .where('slot.date', '<=', previousdate)
    .get()
    .then((snapshot) => {
      const res = snapshot.docs.map((doc) => doc.data());

      const apptsByDate = res.sort((a, b) => {
        if (a.date === b.date) {
          const aPeriod = periods[a.slot.period];
          const bPeriod = periods[b.slot.period];

          if (aPeriod && bPeriod) {
            return aPeriod.startTime.getTime() - bPeriod.startTime.getTime();
          }
        }

        const aDate = parse(a.date, config.dateFormat, new Date());
        const bDate = parse(b.date, config.dateFormat, new Date());

        return aDate.getTime() - bDate.getTime();
      });

      var output = filter(apptsByDate, (user) => {
        return user.slot?.locationId == location.qbenchCustomerId; // && user.slot.date >= todate
      });

      generateReport(output, location.name);
    });
};

export const generateAllAppointmentsReport = async (location: Location) => {
  const pastDates: Array<any> = Last7Days();
  firebase
    .firestore()
    .collection(config.firestoreCollections.qbenchacknowledgement)
    .where('location.qbenchCustomerId', '==', location.qbenchCustomerId)
    .get()
    .then((snapshot) => {
      const res = snapshot.docs.map((doc) => doc.data());

      const apptsByDate = res.sort((a, b) => {
        // if (a.date === b.date) {
        //   const aPeriod = periods[a.slot.period];
        //   const bPeriod = periods[b.slot.period];

        //   if (aPeriod && bPeriod) {
        //     return aPeriod.startTime.getTime() - bPeriod.startTime.getTime();
        //   }
        // }

        const aDate = parse(a.date, config.dateFormat, new Date());
        const bDate = parse(b.date, config.dateFormat, new Date());

        return aDate.getTime() - bDate.getTime();
      });

      generateReport(apptsByDate, location.name);
    });
};

export const downloadAllReports = async () => {
  try {
    firebase
      .firestore()
      .collection('locations')
      .where('isOpened', '==', true)
      .get()
      .then((snapshot: any) => {
        const periods = getPeriodsFromSchedule(config.schedule);

        snapshot.docs.forEach((doc: any) => {
          const location = doc.data();
          firebase
            .firestore()
            .collection('appointments')
            .where('location.qbenchCustomerId', '==', location.qbenchCustomerId)
            .get()
            .then((snapshot: any) => {
              const res = snapshot.docs.map((doc: any) => doc.data());

              const apptsByDate = res.sort((a: any, b: any) => {
                if (a.date === b.date) {
                  const aPeriod = periods[a.slot.period];
                  const bPeriod = periods[b.slot.period];

                  if (aPeriod && bPeriod) {
                    return (
                      aPeriod.startTime.getTime() - bPeriod.startTime.getTime()
                    );
                  }
                }

                const aDate = parse(a.date, config.dateFormat, new Date());
                const bDate = parse(b.date, config.dateFormat, new Date());

                return aDate.getTime() - bDate.getTime();
              });

              generateReport(apptsByDate, location.name);
            });
        });
      });
  } catch (err) {
    console.error('checkResults', err);
  }
};

export const getAllAppointmentsCreatedOnDate = async (date?: string) =>
  firebase
    .functions()
    .httpsCallable('getAllAppointmentsCreatedOnDate')(date)
    .then((res) => generateReport(res.data));

export const getApptReport = async (location: any) => {
  axios({
    method: 'post',
    url:
      'https://us-central1-worksite-labs-ccb7d.cloudfunctions.net/generateAllAppointmentsReport',
    data: { data: { locationId: location.qbenchCustomerId } },
  }).then(function (response: any) {
    if (response.status == 200) {
      generateApptReport(response.data.result.appointments);
      return true;
    }
  });
};

function generateApptReport(appointments: any, locationName?: string) {
  const data = [
    [
      'FirstName',
      'LastName',
      'PatientIdentifier',
      'DOB',
      'Gender',
      'Race',
      'Ethnicity',
      'Patient_Street_Address',
      'Apartment_Number',
      'City',
      'State',
      'Zipcode',
      'PatientPhoneNumber',
      'Patient Email',
      'OrderingFacility',
      'Referring Physician',
      'Physician address',
      'Physician City',
      'Physician State',
      'Physician Zipcode',
      'Physician phone number',
      'Accession #',
      'Specimen Collection Date',
      'Specimen Received Date',
      'Specimen Type',
      'Test reported date',
      'Test Code_LOINC',
      'Test name',
      'Result',
      'PerformingFacility',
      'CLIA',
      'Pregnancy Status',
    ],
    ...appointments.reduce((acc: any[], appt: any) => {
      const a = acc;
      const row = [
        appt.firstName,
        appt.lastName,
        '',
        appt.birthDate,
        appt.sex,
        appt.race,
        appt.ethnicity,
        `${
          appt.address !== undefined
            ? appt.address['address'] +
              ',' +
              appt.address['city'] +
              ',' +
              appt.address['state'] +
              ',' +
              appt.address['zipCode']
            : ''
        }`,

        `${appt.address !== undefined ? appt.address['address'] : ''}`,
        `${appt.address !== undefined ? appt.address['city'] : ''}`,
        `${appt.address !== undefined ? appt.address['state'] : ''}`,
        `${appt.address !== undefined ? appt.address['zipCode'] : ''}`,
        appt.phone,
        appt.email,
        'Work Site Lab',
        '',
        '',
        '',
        '',
        '',
        '',
        appt.custom_formatted_id,
        `${appt.date}`,
        `${
          appt.samples !== undefined &&
          appt.samples.time_of_collection !== undefined
            ? appt.samples.time_of_collection
            : ''
        }`,
        `${
          appt.samples !== undefined &&
          appt.samples.accessioning_type !== undefined
            ? appt.samples.accessioning_type['value']
            : ''
        }`,
        `${
          appt.samples !== undefined ? appt.samples['time_of_collection'] : ''
        }`,
        `${
          appt.samples !== undefined ? appt.samples['time_of_collection'] : ''
        }`,
        `${appt.accessioning_type !== undefined ? appt.accessioning_type : ''}`,
        `${appt.tests !== undefined ? appt.tests['results'] : ''}`,
        '',
        '',
        '',
      ];

      a.push(row);

      return a;
    }, []),
  ];

  const buffer = xlsx.build([{ name: 'mySheetName', data: data }]);
  const blob = new Blob([buffer]);

  saveAs(
    blob,
    `LAC_WorkSiteLab_Neg_${format(new Date(), config.fileDateFormat)}.xlsx`
  );
}

export const getSlotsByLocationId = async (locationId: any) =>
  firebase
    .functions()
    .httpsCallable('getSlotsByLocationId')(locationId)
    .then((res: any) => {
      if (res.data) {
        return res.data.slots[0].slots;
      } else {
        return [];
      }
    });

export const getSlotsConfigurations = async () =>
  firebase.functions().httpsCallable('getSlotsConfigurations')();