import React, { useMemo } from 'react';
import { Field, FieldProps } from 'formik';
import styled from 'styled-components';

import { ErrorText } from '../styled';
import { colors } from '../../../../styles/colors';
import { useStepsDispatch, useStepsState } from '../../../schedule/provider';
import { Location } from '../../models';
import { Breakpoints } from '../../../../dictionaries';
import { useSharedDispatch, useSharedState } from '../../provider';
import AlertModal from "../AlertModal";
import { cloneDeep } from "lodash";
import { getSlotsByLocationId } from '../../api';
import { useAdminDispatch, useAdminState } from '../../providers/admin/provider';
import { useManageDispatch, useManageState } from '../../providers/manage/provider';
import { includes } from "lodash";
interface ButtonProps {
	selected: boolean;
}

const LocationButton = styled.button`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
  align-items: start;
  border: 0;
  background: ${({ selected }: ButtonProps) =>
		selected ? colors.blue : colors.blue8};
  color: ${({ selected }: ButtonProps) =>
		selected ? colors.white : colors.black};
  transition: all 0.2s ease-in-out;
  padding: 18px 20px;
  cursor: pointer;
  margin-bottom: 4px;

  @media (min-width: ${Breakpoints.sm}px) {
    padding: 24px 33px;
  }
`;

const Info = styled.div`
  text-align: left;
`;

const Name = styled.div`
  font-weight: bold;
  margin-bottom: 2px;
`;

const LocationsContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 45px;
`;

const Error = styled.div`
  position: absolute;
  bottom: -25px;
  left: 0;
`;

const Tag = styled.div`  
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
    position: absolute;
    width: 217px;
    height: 28px;
    overflow: hidden;
    left: -2px;
    top: 33px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 30px;
    color: ${({ selected }: ButtonProps) =>
		selected ? colors.black : colors.white};
    -webkit-transform: rotate(
      36.31deg
      );
          -ms-transform: rotate(36.31deg);
          -webkit-transform: rotate(
      36.31deg
      );
    background-color:  ${({ selected }: ButtonProps) =>
		selected ? colors.white : colors.blue};
    `

const TagBox = styled.div`
    position: absolute;
    width: 164px;
    height: 113px;
    right: 0px;
    top: 1px;
    overflow: hidden;
`
const RapidTag = styled.div`  
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
    position: absolute;
    width: 222px;
    height: 28px;
    overflow: hidden;
    left: -13px;
    top: 42px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 30px;
    color: ${({ selected }: ButtonProps) =>
		selected ? colors.black : colors.white};
    -webkit-transform: rotate(
      36.31deg
      );
          -ms-transform: rotate(36.31deg);
          -webkit-transform: rotate(
      36.31deg
      );
    background-color:  ${({ selected }: ButtonProps) =>
		selected ? colors.white : colors.blue};
    `

const RapidTagBox = styled.div`
    position: absolute;
    width: 195px;
    height: 113px;
    right: 0px;
    top: 1px;
    overflow: hidden;
`
interface props {
	confirmAndPay?: boolean;
	isManage?: boolean;
}

const LocationButtonGroup: React.FC<props> = ({ confirmAndPay, isManage }) => {

	const { updateFormValues, toggleShowAlertModal, updateSlotsList } = useStepsDispatch();
	const { updateAdminSlots } = useAdminDispatch();
	const { upateTestType } = useSharedDispatch();
	const { updateRescheduleSlots, updateAppointment, updateStandradTest } = useManageDispatch();
	const { appointment } = useManageState();
	const { form: { isExpressSameDayTest, slot, location, travelType, isRapidTest }, showAlertModal, showChangeLocationModal } = useStepsState();
	const { locations } = useSharedState();
	const closeModal = () => toggleShowAlertModal(false);
	const rollbackLocation = useMemo(() => cloneDeep(location), [showChangeLocationModal]);
	const rollbackSlot = useMemo(() => cloneDeep(slot), [showChangeLocationModal]);
	const rollbackisExpressSameDayTest = useMemo(() => cloneDeep(isExpressSameDayTest), [showChangeLocationModal]);

	const {  Editappointments } = useAdminState();
	const appointmentdata: any = Editappointments !== null ? Editappointments[0] : null

	let IstraveltypeINT: boolean = false;
	let IstraveltypeDOM: boolean = false;

	if (travelType !== null && travelType !== undefined && travelType !== "") {
		IstraveltypeINT = travelType == "INT" ? true : false;
		IstraveltypeDOM = travelType == "DOM" ? true : false;
	} else if(appointment !== null && appointment !== undefined){
		IstraveltypeINT = appointment.travelType == "INT" ? true : false;
		IstraveltypeDOM = appointment.travelType == "DOM" ? true : false;
	} else {
		IstraveltypeINT = appointmentdata.travelType == "INT" ? true : false;
		IstraveltypeDOM = appointmentdata.travelType == "DOM" ? true : false;
	}

	return (
		<>
			<Field
				name="location"
				validate={(value: Location | null) => {
					let error;

					if (!value) {
						error = 'Please choose location';
					}

					return error;
				}}
			>
				{({ form: { setFieldValue }, field, meta: { error } }: FieldProps) => (
					<LocationsContainer>
						{locations &&
							locations.map((loc: any, index: any) => {

								if (IstraveltypeDOM === loc.isDomestic && loc.isDomestic === true) {
									return(
										<LocationButton
											key={loc.name}
											type="button"
											selected={field.value?.name === loc.name}
											onClick={async () => {
												
												setFieldValue('location', loc);
												localStorage.setItem('location', JSON.stringify(loc))

												updateFormValues({ location: loc, slot: undefined, reservationId:  null });

												if (loc?.hasVipSlots == false) {
													upateTestType(true);
													updateFormValues({ isExpressSameDayTest: false });

												}

												updateAppointment({
													...appointment,
													location: loc,
													slot: undefined,
													reservationId: null
												});

												if (loc?.hasVipSlots == false && loc?.rapidTest == false) {
													updateStandradTest(true);
													updateAppointment({
														...appointment,
														location: loc,
														slot: undefined,
														isExpressSameDayTest: false,
														isRapidTest: false
													})

												}else if(loc?.hasVipSlots == true && loc?.rapidTest == false) {
													upateTestType(false);
													updateStandradTest(false);
													updateAppointment({
														...appointment,
														location: loc,
														slot: undefined,
														isExpressSameDayTest: true,
														isRapidTest: false
													})

													updateFormValues({ 
														location: loc, 
														slot: undefined,
														isRapidTest: false,
														isExpressSameDayTest: true,
													});
												}

												if (confirmAndPay && isExpressSameDayTest  &&loc?.hasVipSlots == false) {
													toggleShowAlertModal(true);
												}else if(confirmAndPay && isRapidTest && loc?.rapidTest == false){
													toggleShowAlertModal(true);
												}

											}}
										>
											<Info>
												<Name>{loc.name}</Name>
												{loc.address1}
												<br />
												{loc.address2}
											</Info>
											{loc.hasVipSlots &&
												<TagBox >
													<Tag selected={field.value?.name === loc.name}>
														EXPRESS TESTING AVAILABLE
													</Tag>
												</TagBox>
											}
											{ loc.rapidTest &&
												<RapidTagBox >
													<RapidTag selected={field.value?.name === loc.name}>
														RAPID TESTING AVAILABLE
													</RapidTag>
												</RapidTagBox>
											}
										</LocationButton>
								)
								}
								else if (IstraveltypeINT === loc.isInternational && loc.isInternational === true) {
									return(
										<LocationButton
											key={loc.name}
											type="button"
											selected={field.value?.name === loc.name}
											onClick={async () => {
												setFieldValue('location', loc);
												localStorage.setItem('location', JSON.stringify(loc))
												// const periods: any = await getSlotsByLocationId(loc.qbenchCustomerId);
												// updateSlotsList(periods);
												// updateAdminSlots(periods);
												// updateRescheduleSlots(periods);
												updateFormValues({ location: loc, slot: undefined });

												if (loc?.hasVipSlots == false) {
													upateTestType(true);
													updateFormValues({ isExpressSameDayTest: false });

												}

												updateAppointment({
													...appointment,
													location: loc,
													slot: undefined,
												});

												if (loc?.hasVipSlots == false && loc?.hasVipSlots == false) {
													updateStandradTest(true);
													updateAppointment({
														...appointment,
														location: loc,
														slot: undefined,
														isExpressSameDayTest: false
													})

												}else if(loc?.hasVipSlots == true && loc?.rapidTest == false) {
													upateTestType(false);
													updateStandradTest(false);
													updateAppointment({
														...appointment,
														location: loc,
														slot: undefined,
														isExpressSameDayTest: true,
														isRapidTest: false
													})

													updateFormValues({ 
														location: loc, 
														slot: undefined,
														isRapidTest: false,
														isExpressSameDayTest: false,
													});
												}

												if (confirmAndPay && isExpressSameDayTest  &&loc?.hasVipSlots == false) {
													toggleShowAlertModal(true);
												}else if(confirmAndPay && isRapidTest && loc?.rapidTest == false){
													toggleShowAlertModal(true);
												}

											}}
										>
											<Info>
												<Name>{loc.name}</Name>
												{loc.address1}
												<br />
												{loc.address2}
											</Info>
											{loc.hasVipSlots &&
												<TagBox >
													<Tag selected={field.value?.name === loc.name}>
														EXPRESS TESTING AVAILABLE
													</Tag>
												</TagBox>
											}
											{ loc.rapidTest &&
												<RapidTagBox >
													<RapidTag selected={field.value?.name === loc.name}>
														RAPID TESTING AVAILABLE
													</RapidTag>
												</RapidTagBox>
											}
										</LocationButton>
								)
								}
								// if (travelType !== null && travelType !== null && travelType == "INT" && !includes([119, 62], loc.qbenchCustomerId)) {
								// 	return <div />;
								// } else {
								// 	return(
								// 			<LocationButton
								// 				key={loc.name}
								// 				type="button"
								// 				selected={field.value?.name === loc.name}
								// 				onClick={async () => {
								// 					setFieldValue('location', loc);
								// 					localStorage.setItem('location', JSON.stringify(loc))
								// 					const periods: any = await getSlotsByLocationId(loc.qbenchCustomerId);
								// 					updateSlotsList(periods);
								// 					updateAdminSlots(periods);
								// 					updateRescheduleSlots(periods);
								// 					updateFormValues({ location: loc, slot: undefined });

								// 					if (loc?.hasVipSlots == false) {
								// 						upateTestType(true);
								// 						updateFormValues({ isExpressSameDayTest: false });

								// 					}

								// 					updateAppointment({
								// 						...appointment,
								// 						location: loc,
								// 						slot: undefined,
								// 					});

								// 					if (loc?.hasVipSlots == false) {
								// 						updateStandradTest(true);
								// 						updateAppointment({
								// 							...appointment,
								// 							location: loc,
								// 							slot: undefined,
								// 							isExpressSameDayTest: false
								// 						})

								// 					}

								// 					if (confirmAndPay && isExpressSameDayTest && loc?.hasVipSlots == false) {
								// 						toggleShowAlertModal(true);
								// 					}

								// 				}}
								// 			>
								// 				<Info>
								// 					<Name>{loc.name}</Name>
								// 					{loc.address1}
								// 					<br />
								// 					{loc.address2}
								// 				</Info>
								// 				{loc.hasVipSlots &&
								// 					<TagBox >
								// 						<Tag selected={field.value?.name === loc.name}>
								// 							EXPRESS TESTING AVAILABLE
								// 						</Tag>
								// 					</TagBox>
								// 				}
								// 			</LocationButton>
								// 	)
								// }

							})}
						<Error>
							<ErrorText hasError={error !== undefined}>{error}</ErrorText>
						</Error>

						<AlertModal
							confirmAndPay={true}
							title={"Are you sure you want to move your appointment location?"}
							content={"You have selected a site that does not have the express testing. By selecting this location option you will be downgraded to standard testing, which costs $90 and has a 24-hour turnaround time."}
							onConfirm={() => {
								// alert("confirm");
								updateFormValues({ update: location });
								updateFormValues({ update: slot });
								setFieldValue("location", location);
								upateTestType(true);
								updateStandradTest(true);
								updateFormValues({ isExpressSameDayTest: false });
								updateAppointment({
									...appointment,
									isExpressSameDayTest: false
								})
								closeModal();
							}}
							onCancel={() => {
								upateTestType(false);
								updateFormValues({ location: rollbackLocation, slot: rollbackSlot, isExpressSameDayTest: rollbackisExpressSameDayTest });
								setFieldValue("location", rollbackLocation);
							}}
						/>
					</LocationsContainer>
				)}
			</Field>
		</>
	);
};

export default LocationButtonGroup;
