export default {
  schedule: '/',
  admin: '/admin',
  manage: '/manage',
  report: '/report',
  dashboard: '/dashboard',
  commonpass: {
    default: '/common-pass/login',
    authorize: '/common-pass/authorize',
    verify: '/common-pass/verify',
  },
}